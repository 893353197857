.voucher {
  width: 970px;
}

.voucher-block {
  position: relative;
  width: 970px;

}

#voucher-block-parent-student {
  .parent-name {
    position: absolute;
    top: 269px;
    left: 604px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .student-name {
    position: absolute;
    top: 297px;
    left: 484px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .phone {
    position: absolute;
    top: 324px;
    left: 510px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .registered-date {
    position: absolute;
    top: 324px;
    left: 836px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }
}

#voucher-block-student-only {
  height: 485px;
  .parent-name {
    position: absolute;
    top: 269px;
    left: 604px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .student-name {
    position: absolute;
    top: 281px;
    left: 529px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .phone {
    position: absolute;
    top: 314px;
    left: 530px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .registered-date {
    display: none;
  }

  .expiration-date {
    position: absolute;
    top: 239px;
    left: 697px;
    color: orange;
    font-weight: bold;
    font-size: 20px;
  }

  .code {
    position: absolute;
    top: 450px;
    left: 380px;
    color: #ccc;
    font-weight: bold;
    font-size: 17px;
  }
}
